// Generated by CoffeeScript 1.10.0
(function() {
  (function(p, $, d, w) {
    "use strict";
    var HevelopSlider;
    HevelopSlider = (function() {
      var self;

      self = false;

      function HevelopSlider() {
        self = this;
        this.isMobileDevice = /Android|Kindle|Silk|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent);
      }

      HevelopSlider.prototype.isRunnable = function() {
        return true;
      };

      HevelopSlider.prototype.ready = function() {
        $('.aitch-slider').each(function() {
          var hevelopSlider, hidePagination, loopSlide, numSlides, sliderAutoplay, sliderId, sliderSpeed, sliderWrapId, swiper;
          hevelopSlider = $(this);
          sliderId = hevelopSlider.attr('id');
          sliderWrapId = $('#' + sliderId).attr('data-wrapper-id');
          sliderAutoplay = hevelopSlider.attr('data-slider-autoplay');
          sliderSpeed = hevelopSlider.data("animation-speed");
          numSlides = hevelopSlider.data("slide-desktop");
          hidePagination = hevelopSlider.data("show-pagination");
          loopSlide = hevelopSlider.data("slider-loop");
          if ($(w).width() < 768 && $(w).width() > 640) {
            numSlides = hevelopSlider.data("slide-tablet");
          }
          if ($(w).width() < 640) {
            numSlides = hevelopSlider.data("slide-mobile");
          }
          if ($('.swiper-wrapper', hevelopSlider).children('.swiper-slide').length < numSlides) {
            loopSlide = false;
          }
          swiper = new Swiper('#' + sliderId, {
            loop: true,
            preventClicks: false,
            speed: sliderSpeed,
            scrollbarDraggable: false,
            autoplay: true,
            autoplayDisableOnInteraction: true,
            paginationHide: hidePagination,
            slidesPerView: numSlides,
            pagination: {
              el: '.swiper-pagination'
            },
            navigation: {
              nextEl: '.parent-swiper-button-next',
              prevEl: '.parent-swiper-button-prev',
            },
            effect: 'slide'
          });
        });
        $(w).load(function() {
          self.bindings();
        });
      };

      HevelopSlider.prototype.load = function() {};

      HevelopSlider.prototype.bindings = function() {
        $(w).resize(function() {
          return $('.aitch-slider').each(function() {
            var hidePagination, idSlider, loopSlide, numSlides, sliderWrapId, swiperSlider;
            idSlider = $(this).attr('id');
            sliderWrapId = $('#' + idSlider).attr('data-wrapper-id');
            swiperSlider = $('#' + idSlider)[0].swiper;
            numSlides = $('#' + idSlider).data("slide-desktop");
            loopSlide = true;
            if ($(w).width() < 768 && $(w).width() > 640) {
              numSlides = $('#' + idSlider).data("slide-tablet");
            }
            if ($(w).width() < 640) {
              numSlides = $('#' + idSlider).data("slide-mobile");
            }
            if ($('#' + idSlider + ' .swiper-wrapper').children('.swiper-slide').length < numSlides) {
              loopSlide = false;
              hidePagination = true;
              $('#' + sliderWrapId + '.dishes-slider .swiper-next').addClass("hidden");
              $('#' + sliderWrapId + '.dishes-slider .swiper-prev').addClass("hidden");
            }
            swiperSlider.params.slidesPerView = numSlides;
            swiperSlider.params.loop = loopSlide;
            return swiperSlider.init();
          });
        });
      };

      return HevelopSlider;

    })();
    w.hevelopSlider = new HevelopSlider();
    hevelopSlider.ready();
  })($, jQuery, document, window);

}).call(this);
